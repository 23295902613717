@import "./variables.scss";

@mixin backgroundImage($image) {
  background-image: url($image);
  background-repeat: no-repeat;
}

@mixin font(
  $font-size,
  $font-weight: normal,
  $line-height: 20px,
  $color: $black,
  $font-style: normal,
  $font-family: $global-font-family
) {
  color: $color;
  font-weight: $font-weight;
  font-size: $font-size;
  font-family: $font-family;
  font-style: $font-style;
  line-height: $line-height;
}

@mixin absolute($top: unset, $right: unset, $bottom: unset, $left: unset) {
  position: absolute;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin flex($justify-content: center, $align-items: center, $flex-direction: row) {
  display: flex;
  flex-direction: $flex-direction;
  align-items: $align-items;
  justify-content: $justify-content;
}

@mixin media($media-width) {
  @media (max-width: #{$media-width}) {
    @content;
  }
}

@mixin pseudoElementIcon($icon, $width, $height) {
  @include backgroundImage($icon);

  display: block;
  width: $width;
  height: $height;
  content: "";
}

@mixin truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin backButton {
  @include flex(flex-start);

  height: 16px;
  color: $helper-text-color;
  font-weight: bold;
  font-size: $font-size-normal;
  font-family: $global-font-family;
  line-height: 18px;
  letter-spacing: -0.3px;
  cursor: pointer;
  &::before {
    @include pseudoElementIcon("/icons/button/back.svg", 10px, 12px);

    margin-right: 3px;
  }
}
