@import "../../mixins";
@import "../../variables";

.icon {
  cursor: pointer;
  &.person {
    @include backgroundImage("/icons/general/person.svg");
  }

  &.user-login {
    @include backgroundImage("/icons/general/user-login.svg");
  }

  &.password-show {
    width: 40px;
    height: 40px;
    background-position: center;
    @include backgroundImage("/icons/general/password-show.svg");
  }

  &.password-hide {
    width: 40px;
    height: 40px;
    background-position: center;
    @include backgroundImage("/icons/general/password-hide.svg");
  }

  &.heart-selected {
    @include backgroundImage("/icons/general/heart-selected.svg");
  }

  &.heart-empty {
    @include backgroundImage("/icons/general/heart-empty.svg");
  }

  &.document {
    @include backgroundImage("/icons/general/document.svg");
  }

  &.document-bg {
    @include backgroundImage("/icons/general/document-bg.svg");
  }

  &.interactive-guide {
    @include backgroundImage("/icons/general/interactive-guide.svg");
  }

  &.interactive-guide-card {
    @include backgroundImage("/icons/general/interactive-guide-card.svg");
  }

  &.arrow-closed {
    @include backgroundImage("/icons/input/arrow-closed.svg");
  }

  &.arrow-opened {
    @include backgroundImage("/icons/input/arrow-opened.svg");
  }

  &.delete {
    @include backgroundImage("/icons/input/delete.svg");
  }

  &.check-mark {
    @include backgroundImage("/icons/input/check-mark.svg");
  }

  &.find {
    @include backgroundImage("/icons/input/find.svg");
  }

  &.plus {
    @include backgroundImage("/icons/button/plus.svg");
  }

  &.plus-gray {
    @include backgroundImage("/icons/button/plus-gray.svg");
  }

  &.filter {
    @include backgroundImage("/icons/button/filter.svg");
  }

  &.img {
    @include backgroundImage("/icons/input/img.svg");
  }

  &.play {
    @include backgroundImage("/icons/button/play.svg");
  }

  &.export {
    @include backgroundImage("/icons/button/export.svg");
  }

  &.play-white {
    @include backgroundImage("/icons/button/play-white.svg");
  }

  &.play-red {
    @include backgroundImage("/icons/button/play-red.svg");
  }

  &.send-red {
    width: 26px;
    height: 40px;
    margin: unset;
    background-position: left;
    @include backgroundImage("/icons/button/send-red.svg");

    &.disabled {
      @include backgroundImage("/icons/button/send-gray.svg");
    }
  }

  &.share {
    @include backgroundImage("/icons/button/share.svg");
  }

  &.share-white {
    @include backgroundImage("/icons/button/share-white.svg");
  }

  &.share-protocols {
    width: 30px;
    height: 40px;
    background-position: left;
    @include backgroundImage("/icons/button/share.svg");
    @include media($mobile-width) {
      margin: unset;
    }
  }

  &.read-more {
    @include backgroundImage("/icons/input/arrow-right-long.svg");
  }

  &.dots-white {
    @include backgroundImage("/icons/button/dots-white.svg");
  }

  &.dots-dark {
    @include backgroundImage("/icons/button/dots-dark.svg");
  }

  &.edit {
    @include backgroundImage("/icons/button/edit.svg");
  }

  &.pdf {
    @include backgroundImage("/icons/general/document-colored.svg");
  }

  &.docx {
    @include backgroundImage("/icons/general/docx.svg");
  }

  &.right-arrow {
    @include backgroundImage("/icons/input/arrow-right-long.svg");
  }

  &.custom-protocol {
    @include backgroundImage("/icons/protocol/custom.svg");
  }

  &.native-protocol {
    @include backgroundImage("/icons/protocol/native.svg");
  }

  &.close-gray {
    @include backgroundImage("/icons/general/close-gray.svg");
  }

  &.close-white {
    @include backgroundImage("/icons/general/close-white.svg");
  }

  &.check {
    @include backgroundImage("/icons/protocol/check.svg");
  }

  &.uncheck {
    @include backgroundImage("/icons/protocol/uncheck.svg");
  }

  &.copy {
    @include backgroundImage("/icons/protocol/copy.svg");
  }

  &.show-guide {
    @include backgroundImage("/icons/guide/show.svg");
  }

  &.hide-guide {
    @include backgroundImage("/icons/guide/hide.svg");
  }

  &.safari-download {
    @include backgroundImage("/icons/general/safari-download.svg");
  }

  &.reject {
    @include backgroundImage("/icons/button/reject.svg");
  }

  &.offline {
    @include backgroundImage("/icons/general/offline.svg");

    width: 34px;
    height: 34px;
  }

  &.available-offline {
    @include backgroundImage("/icons/general/download-offline.svg");
  }

  &.pdf,
  &.docx {
    width: 26px;
    height: 30px;
  }
}
