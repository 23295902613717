@import "./variables.scss";
@import "./mixins.scss";
@import "./items";

@font-face {
  font-family: Merriweather Sans;
  src: url(../fonts/MerriweatherSans-Regular.ttf);
}

@font-face {
  font-family: Mukta;
  src: url(../fonts/Mukta-Regular.ttf);
}

/* Chrome, Safari, Edge, Opera - Hide Arrows From Input Number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

/* Firefox - Hide Arrows From Input Number */
input[type="number"] {
  -moz-appearance: textfield;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

body {
  width: 100%;
  height: var(--app-height);
  margin: 0;
  overflow: hidden;
  font-family: $global-font-family;
  background-color: $bg-color;
}

p {
  margin: 0;
}

nav {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;

  a {
    color: $white;
    text-decoration: none;
  }
}

nav ul,
nav li {
  margin: 0;
  padding: 0;
  outline: 0;
}

ul {
  margin: 0 15px;
  padding: 0 15px;
}

.line {
  display: flex;
}

.block {
  flex: 50%;
  flex-wrap: wrap;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-wrap {
  flex-wrap: wrap;
}

.hide-mobile {
  @include media($mobile-width) {
    display: none;
  }
}

.hide-tablet {
  @include media($tablet-width) {
    display: none !important;
  }
}

.hide-large-tablet {
  @include media($large-tablet-width) {
    display: none !important;
  }
}

.hide-desktop {
  @media (max-width: #{$tablet-width}) {
    display: none;
  }
}

.pswp img {
  max-width: none;
  object-fit: contain;
}

.hidden {
  display: none !important;
}
