@import "../../variables.scss";
@import "../../mixins.scss";

button:focus,
input:focus {
  outline: 0;
}

label *:focus {
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

.input-max-counter {
  position: absolute;
  top: 7px;
  right: 0;
  color: $label-color;
  font-size: $font-size-small;
  font-family: $second-global-font-family;
  @include media($tablet-width) {
    top: 0;
  }
}
