//  Foundation for Sites Settings
//  -----------------------------
//
//  Table of Contents:
//
//   1. Global
//   2. Input
//   3. Label
//   3. Media
//   5. Z-indexes Map

// 1. Global
// ------------------------------
$white: #ffff;
$black: #181920;
$dark: #2b4144;
$red: #cf202f;
$grey: #828f92;
$error-notification-color: #cf202f;
$success-notification-color: #90a495;
$light-orange: #ebd8cb;
$swamp: #8da595;
$light-grey: #e5ebed;
$border-color: #c8cdcf;
$header-color: #2b4144;
$bg-color: #eff0f0;
$error-bg-color: #f5e7e8;
$light-bg-color: #f6f7f8;
$heading-color: #2b4144;
$global-font-family: "Merriweather Sans", sans-serif;
$second-global-font-family: "Mukta", sans-serif;
$shadow: #e6e6e6;
$interactive-giude-bg: #fafbfb;

$main-text-color: $dark;
$helper-text-color: $grey;
$card-background: $white;
$card-border-radius: 10px;
$accent-color: $red;
$border-color: $light-grey;
$disabled-button-color: #c7d4d8;
$image-border-color: #e5ebef;

$title-font-size: 24px;
$font-size-normal: 14px;
$font-size-small: 12px;
$font-size-big: 16px;

$header-height: 70px;

// 2. Input
$input-color: #2b4144;
$input-font-family: "Mukta", sans-serif;
$input-font-size: 14px;
$input-font-weight: 400;
$input-height: 40px;
$input-border-color: #e5ebed;
$input-bg-color: #fff;
$input-border-radius: 10px;

// 3. Label
$label-color: #828f92;
$label-font-family: "Mukta", sans-serif;
$label-font-size: 12px;
$label-font-weight: 400;
$label-required-color: #cf202f;
$label-required-font-size: 16px;

// 4. Media
// ------------------------------
$tiny-width: 375px;
$mobile-width: 479px;
$tablet-width: 991px;
$large-tablet-width: 1100px;
$procedure-type-filter-resize-width: 1445px;

// 6. Z-indexes Map
// ------------------------------
$z-indexes: (
  "negative": -1,
  "light_0": 0,
  "light_1": 1,
  "light_2": 2,
  "light_3": 3,
  "light_4": 4,
  "medium_1": 10,
  "medium_2": 11,
  "large_1": 100,
  "large_2": 101,
  "large_3": 102,
  "large_4": 103,
  "top": 1000,
  "loading": 10000,
);

:export {
  red: $red;
  dark: $dark;
  grey: $grey;
  light-orange: $light-orange;
  swamp: $swamp;
  lightGrey: $light-grey;
  white: $white;
  inputColor: $input-color;
  inputFontFamily: $input-font-family;
  inputFontSize: $input-font-size;
  inputFontWeight: $input-font-weight;
  inputHeight: $input-height;
  inputBorderColor: $input-border-color;
  inputBgColor: $input-bg-color;
  inputBorderRadius: $input-border-radius;
  mobileWidth: $mobile-width;
  tabletWidth: $tablet-width;
  interactiveGiudeBg: $interactive-giude-bg;
  fontSizeNormal: $font-size-normal;
  mainTextColor: $main-text-color;
  accentColor: $accent-color;
  globalFontFamily: $global-font-family;
  tablet: $tablet-width;
  largeTablet: $large-tablet-width;
  disabledButtonColor: $disabled-button-color;
  borderColor: $border-color;
  secondGlobalFontFamily: $second-global-font-family;
}
