@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

.react-toast-notifications__container {
  margin-top: 40px;
  margin-right: 40px;
  padding: 0 !important;
  @include media($tablet-width) {
    margin-top: 16px;
    margin-right: 16px;
  }
}

.notification {
  display: flex;
  justify-content: space-between;
  width: 310px;
  padding: 18.5px 20px;
  color: $white;
  font-size: 14px;
  font-family: $second-global-font-family;
  line-height: 24px;
  background: $success-notification-color;
  border-radius: 8px;
  box-shadow: 0 10px 150px rgba(0, 0, 0, 0.1);

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  .notification-content {
    display: flex;
    flex-direction: column;

    .notification-title {
      margin-bottom: 5px;
      font-weight: 700;
      font-size: 24px;
      font-family: $global-font-family;
    }
  }

  &.error {
    background: $error-notification-color;
  }

  &.success {
    background: $success-notification-color;
  }
}

.notification-message {
  width: 100%;
}

.icon {
  width: 20px;
  height: 25px;
  background-position: center;
}

@include media($mobile-width) {
  .notification {
    width: 248px;
    min-height: 72px;
    .notification-content {
      .notification-title {
        font-size: 16px;
      }
    }
  }
}
